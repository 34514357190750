import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const handleGenerateExcel = async (products) => {
    const columns = [
        { header: 'Código', key: 'sku', width: 20},
        { header: 'Descripción', key: 'description', width: 20},
        { header: 'Cantidad Ajustada', key: 'adjustedQuantityToBuy', width: 20},
        { header: 'Alerta Sobre Compra', key: 'alertOverBuy', width: 20 },
        { header: 'Costo', key: 'cost', width: 20},
        { header: 'Proveedor', key: 'provider', width: 20},
    ]
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Facturas de Compra");
    worksheet.columns = columns;
    products.forEach((product) => {
        worksheet.addRow({
            sku: product.sku,
            description: product.description,
            adjustedQuantityToBuy: product.adjustedQuantityToBuy,
            alertOverBuy: product.adjustedQuantityToBuy / product.quantityToBuy > 1.3 ? 'SI' : 'NO',
            cost: product.cost,
            provider: product.provider
        });
    });

     // Generar archivo Excel y guardarlo
     const buffer = await workbook.xlsx.writeBuffer();
     const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
     saveAs(blob, 'facturascompra.xlsx');
}

const handleGenerateFullExcel = async (products) => {
    const columns = [
        { header: 'Código', key: 'sku', width: 20},
        { header: 'Descripción', key: 'description', width: 20},
        { header: 'Marca', key: 'brand', width: 20},
        { header: 'Proveedor', key: 'provider', width: 20},
        { header: 'Familia', key: 'family', width: 20},
        { header: 'Costo', key: 'cost', width: 20},
        { header: 'Producto Nuevo', key: 'new', width: 20},
        { header: 'Temporalidad', key: 'seasonality', width: 20},
        { header: 'Stock', key: 'stockTotal', width: 20},
        { header: 'Embalaje', key: 'packing', width: 20},
        { header: 'Lead Time', key: 'leadTime', width: 20},
        { header: 'Total Ordenes Pendientes Proveedor', key: 'totalPendingProviderOrders', width: 20},
        { header: 'Total Ordenes Pendientes Cliente', key: 'totalPendingCustomerOrders', width: 20},
        { header: 'Mes 1', key: 'month1', width: 20},
        { header: 'Mes 2', key: 'month2', width: 20},
        { header: 'Mes 3', key: 'month3', width: 20},
        { header: 'Mes 4', key: 'month4', width: 20},
        { header: 'Mes 5', key: 'month5', width: 20},
        { header: 'Mes 6', key: 'month6', width: 20},
        { header: 'Mes 7', key: 'month7', width: 20},
        { header: 'Mes 8', key: 'month8', width: 20},
        { header: 'Mes 9', key: 'month9', width: 20},
        { header: 'Mes 10', key: 'month10', width: 20},
        { header: 'Mes 11', key: 'month11', width: 20},
        { header: 'Mes 12', key: 'month12', width: 20},
        { header: 'Mes 13', key: 'month13', width: 20},
        { header: 'Mes 14', key: 'month14', width: 20},
        { header: 'Mes 15', key: 'month15', width: 20},
        { header: 'Mes 16', key: 'month16', width: 20},
        { header: 'Mes 17', key: 'month17', width: 20},
        { header: 'Mes 18', key: 'month18', width: 20},
        { header: 'Mes Actual', key: 'actualMonth', width: 20},
        { header: 'Ultimos 3 Meses', key: 'last3Months', width: 20},
        { header: 'Ultimos 6 Meses', key: 'last6Months', width: 20},
        { header: 'Ultimos 9 Meses', key: 'last9Months', width: 20},
        { header: 'Ultimos 12 Meses', key: 'last12Months', width: 20},
        { header: 'WMA 3 Meses', key: 'wma3Months', width: 20},
        { header: 'WMA 6 Meses', key: 'wma6Months', width: 20},
        { header: 'WMA 9 Meses', key: 'wma9Months', width: 20},
        { header: 'WMA 12 Meses', key: 'wma12Months', width: 20},
        { header: 'Mes Proyectado', key: 'proyectedMonth', width: 20},
        { header: 'Clasificación ultimo mes', key: 'classificationLastMonth', width: 20},
        { header: 'Clasificacion ultimos 3 meses', key: 'classificationLast3Months', width: 20},
        { header: 'Clasificacion ultimos 6 meses', key: 'classificationLast6Months', width: 20},
        { header: 'Clasificacion ultimos 9 meses', key: 'classificationLast9Months', width: 20},
        { header: 'Clasificacion ultimos 12 meses', key: 'classificationLast12Months', width: 20},
        { header: 'Participacion ultimo mes', key: 'participationLastMonth', width: 20},
        { header: 'Participacion ultimos 3 meses', key: 'participationLast3Months', width: 20},
        { header: 'Participacion ultimos 6 meses', key: 'participationLast6Months', width: 20},
        { header: 'Participacion ultimos 9 meses', key: 'participationLast9Months', width: 20},
        { header: 'Participacion ultimos 12 meses', key: 'participationLast12Months', width: 20},
        { header: 'Meses a comprar', key: 'monthsToBuy', width: 20},
        { header: 'Cantidad a comprar', key: 'quantityToBuy', width: 20},
        { header: 'Cantidad ajustada a comprar', key: 'adjustedQuantityToBuy', width: 20},
        { header: 'Meses venta en bodega', key: 'salesMonthsInWarehouse', width: 20},
    ]
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sabana Datos");
    worksheet.columns = columns;
    products.forEach((product) => {
        worksheet.addRow({
            ...product,
            totalPendingProviderOrders: parseInt(product.totalPendingProviderOrders),
            totalPendingCustomerOrders: parseInt(product.totalPendingCustomerOrders),
        });
    });

     // Generar archivo Excel y guardarlo
     const buffer = await workbook.xlsx.writeBuffer();
     const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
     saveAs(blob, 'sabanaDatos.xlsx');
}

const createExcels = {
    handleGenerateExcel,
    handleGenerateFullExcel
};

export default createExcels;

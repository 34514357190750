import { DataGridPro, GridColDef, GridToolbar, esES, getGridNumericOperators } from "@mui/x-data-grid-pro"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { getMonthForTable } from "../../../utils/getMonthForTable"
import { getSkusWithSales, updateStock } from "../../../services/productService"
import { Alert, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { getAdjustedQuantityToBuy, quantityToBuy } from "../../../utils/formulasForQtyToBuy"
import createExcels from "../../../utils/createExcels"
import { calculateSalesMonthsInWarehouse } from "../../../utils/formulaSalesMonthsInWarehouse"
import CustomMonthSelectModal from "./CustomMonthSelectModal"
import LimitModal from "./LimitModal"
import TrafficLightFilters from "./TrafficLightsFilters"
import CartModal from "./CartModal"; // Modal para mostrar el carrito


interface CartItem {
  sku: string;
  description: string;
  adjustedQuantityToBuy: number;
  quantityToBuy: number;
  cost: number;
  provider: string;
}


const WarehouseView: React.FC = () => {

  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState<ProductWithSalesAndInfo[]>([])
  const [filteredRows, setFilteredRows] = useState<ProductWithSalesAndInfo[]>([])
  const [selectedRows, setSelectedRows] = useState<String[]>([])
  const [openCartModal, setOpenCartModal] = useState<boolean>(false);

  // para el tipo de formato venta seleccionado
  const [selectedSalesType, setSelectedSalesType] = useState<"month18" | "last3Months" | "proyectedMonth" | "last6Months" | "last9Months" | "last12Months" | "wma3Months" | "wma6Months" | "wma9Months" | "wma12Months" | "custom">("month18")
  const [monthsInWarehouse, setMonthsInWarehouse] = useState<number>(1) // meses venta en bodega
  const [includeReception, setIncludeReception] = useState<boolean>(false) // incluir recepción de pendientes en el modelo
  const [criticalStockFilter, setCriticalStockFilter] = useState<boolean>(false) // filtro de stock crítico
  const [temporality, setTemporality] = useState<number>(0) // temporalidad en porcentaje
  const [expectedGrowth, setExpectedGrowth] = useState<number>(0) // crecimiento esperado en porcentaje
  const [periodForClassification, setPeriodForClassification] = useState<"LastMonth" | "Last3Months" | "Last6Months" | "Last12Months">("LastMonth") // periodo para clasificación de producto
  const [productClassificationChecked, setProductClassificationChecked] = useState<{
    A: boolean,
    B: boolean,
    C: boolean
  }>({
    A: true,
    B: true,
    C: true,
  }) // clasificación de productos
  const [newProductsChecked, setNewProductsChecked] = useState<boolean>(false) // productos nuevos
  const [seasonalityChecked, setSeasonalityChecked] = useState<{
    TT: boolean,
    V: boolean,
    I: boolean
  }>({
    TT: true,
    V: true,
    I: true
  }) // estacionalidad
  const [openAverageModal, setOpenAverageModal] = useState<boolean>(false) // para abrir el modal en caso de seleccion de meses personalizados
  const [averageMonths, setAverageMonths] = useState<string[]>([]) // meses seleccionados para promedio
  const firstUpdate = useRef(true);
  const [filterModel, setFilterModel] = useState(undefined);
  const [totalOcAmount, setTotalOcAmount] = useState<number>(0);
  const [openLimitModal, setOpenLimitModal] = useState<boolean>(false);
  // seleccion de semaforo
  const [trafficLightChecked, setTrafficLightChecked] = useState<{
    green: boolean,
    yellow: boolean,
    red: boolean
  }>({
    green: true,
    yellow: true,
    red: true
  })
  const [topLimit, setTopLimit] = useState<number>(0);
  const [bottomLimit, setBottomLimit] = useState<number>(0);

  // Carrito en el localStorage
  const [cart, setCart] = useState<CartItem[]>([]);

  // Cargar el carrito desde el localStorage
  const loadCartFromLocalStorage = () => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      setCart(JSON.parse(storedCart));
    }
  };

  // Guardar el carrito en localStorage
  const saveCartToLocalStorage = (cart: CartItem[]) => {
    localStorage.setItem("cart", JSON.stringify(cart));
  };

  // Filtrar las filas del carrito al renderizar la tabla
  const filterRowsWithCart = (rows: ProductWithSalesAndInfo[]) => {
    const cartSkus = cart.map(item => item.sku);
    return rows.filter(row => !cartSkus.includes(row.sku));
  };

  // Agregar filas seleccionadas al carrito
  const addToCart = () => {
    const newCartItems = filteredRows
      .filter(row => selectedRows.includes(row.sku))
      .map(row => ({
        sku: row.sku,
        description: row.description,
        adjustedQuantityToBuy: row.adjustedQuantityToBuy,
        quantityToBuy: row.quantityToBuy,
        cost: row.cost,
        provider: row.provider,
      }));

    const updatedCart = [...cart, ...newCartItems];
    setCart(updatedCart);
    saveCartToLocalStorage(updatedCart);
  };

  const columns: GridColDef[] = [
    { field: "sku", headerName: "SKU" },
    { field: "description", headerName: "Descripcion" },
    { field: "brand", headerName: "Marca" },
    { field: "provider", headerName: "Proveedor" },
    { field: "family", headerName: "Familia" },
    {
      field: "cost", headerName: "Costo Unitario", type: "number"
    },
    {
      field: "stockTotal", headerName: "Stock Bodega", type: "number", valueGetter(params) {
        if (!params.row.stockTotal) {
          return 0
        }
        return parseInt(params.row.stockTotal)
      }
    },
    {
      field: "packing", headerName: "Master Pack", type: "number", valueGetter(params) {
        if (!params.row.packing) {
          return 0
        }
        return parseInt(params.row.packing)
      }
    },
    {
      field: "leadTime", headerName: "Lead Time", type: "number", valueGetter(params) {
        if (!params.row.leadTime) {
          return 0
        }
        return parseInt(params.row.leadTime)
      }
    },
    {
      field: "totalPendingProviderOrders", headerName: "Pendientes de Llegada", type: "number", valueGetter(params) {
        if (!params.row.totalPendingProviderOrders) {
          return 0
        }
        return parseInt(params.row.totalPendingProviderOrders)
      }
    },
    {
      field: "totalPendingCustomerOrders", headerName: "Pendientes de Entrega Clientes", type: "number", valueGetter(params) {
        if (!params.row.totalPendingCustomerOrders) {
          return 0
        }
        return parseInt(params.row.totalPendingCustomerOrders)
      }
    },
    {
      field: "month1", headerName: getMonthForTable(1), type: "number", valueGetter(params) {
        if (!params.row.month1) {
          return 0
        }
        return parseInt(params.row.month1)
      }
    },
    {
      field: "month2", headerName: getMonthForTable(2), type: "number", valueGetter(params) {
        if (!params.row.month2) {
          return 0
        }
        return parseInt(params.row.month2)
      }
    },
    {
      field: "month3", headerName: getMonthForTable(3), type: "number", valueGetter(params) {
        if (!params.row.month3) {
          return 0
        }
        return parseInt(params.row.month3)
      }
    },
    {
      field: "month4", headerName: getMonthForTable(4), type: "number", valueGetter(params) {
        if (!params.row.month4) {
          return 0
        }
        return parseInt(params.row.month4)
      }
    },
    {
      field: "month5", headerName: getMonthForTable(5), type: "number", valueGetter(params) {
        if (!params.row.month5) {
          return 0
        }
        return parseInt(params.row.month5)
      }
    },
    {
      field: "month6", headerName: getMonthForTable(6), type: "number", valueGetter(params) {
        if (!params.row.month6) {
          return 0
        }
        return parseInt(params.row.month6)
      }
    },
    {
      field: "month7", headerName: getMonthForTable(7), type: "number", valueGetter(params) {
        if (!params.row.month7) {
          return 0
        }
        return parseInt(params.row.month7)
      }
    },
    {
      field: "month8", headerName: getMonthForTable(8), type: "number", valueGetter(params) {
        if (!params.row.month8) {
          return 0
        }
        return parseInt(params.row.month8)
      }
    },
    {
      field: "month9", headerName: getMonthForTable(9), type: "number", valueGetter(params) {
        if (!params.row.month9) {
          return 0
        }
        return parseInt(params.row.month9)
      }
    },
    {
      field: "month10", headerName: getMonthForTable(10), type: "number", valueGetter(params) {
        if (!params.row.month10) {
          return 0
        }
        return parseInt(params.row.month10)
      }
    },
    {
      field: "month11", headerName: getMonthForTable(11), type: "number", valueGetter(params) {
        if (!params.row.month11) {
          return 0
        }
        return parseInt(params.row.month11)
      }
    },
    {
      field: "month12", headerName: getMonthForTable(12), type: "number", valueGetter(params) {
        if (!params.row.month12) {
          return 0
        }
        return parseInt(params.row.month12)
      },
    },
    {
      field: "month13", headerName: getMonthForTable(13), type: "number", valueGetter(params) {
        if (!params.row.month13) {
          return 0
        }
        return parseInt(params.row.month13)
      }
    },
    {
      field: "month14", headerName: getMonthForTable(14), type: "number", valueGetter(params) {
        if (!params.row.month14) {
          return 0
        }
        return parseInt(params.row.month14)
      }
    },
    {
      field: "month15", headerName: getMonthForTable(15), type: "number", valueGetter(params) {
        if (!params.row.month15) {
          return 0
        }
        return parseInt(params.row.month15)
      }
    },
    {
      field: "month16", headerName: getMonthForTable(16), type: "number", valueGetter(params) {
        if (!params.row.month16) {
          return 0
        }
        return parseInt(params.row.month16)
      }
    },
    {
      field: "month17", headerName: getMonthForTable(17), type: "number", valueGetter(params) {
        if (!params.row.month17) {
          return 0
        }
        return parseInt(params.row.month17)
      }
    },
    {
      field: "month18", headerName: getMonthForTable(18), type: "number", valueGetter(params) {
        if (!params.row.month18) {
          return 0
        }
        return parseInt(params.row.month18)
      }
    },
    {
      field: "actualMonth", headerName: "Mes Actual", type: "number", valueGetter(params) {
        if (!params.row.actualMonth) {
          return 0
        }
        return parseInt(params.row.actualMonth)
      }
    },
    {
      field: "last3Months", headerName: "Prom 3 meses", type: "number", valueGetter(params) {
        if (!params.row.last3Months) {
          return 0
        }
        return parseInt(params.row.last3Months)
      },
    },
    {
      field: "last6Months", headerName: "Prom 6 meses", type: "number", valueGetter(params) {
        if (!params.row.last6Months) {
          return 0
        }
        return parseInt(params.row.last6Months)
      }
    },
    {
      field: "last9Months", headerName: "Prom 9 meses", type: "number", valueGetter(params) {
        if (!params.row.last9Months) {
          return 0
        }
        return parseInt(params.row.last9Months)
      }
    },
    {
      field: "last12Months", headerName: "Prom 12 meses", type: "number", valueGetter(params) {
        if (!params.row.last12Months) {
          return 0
        }
        return parseInt(params.row.last12Months)
      }
    },
    {
      field: "proyectedMonth", headerName: "Proyectado del mes", type: 'number',
      valueGetter(params) {
        if (!params.row.proyectedMonth) {
          return 0
        }
        return parseInt(params.row.proyectedMonth)
      },
    },
    { // wma
      field: "wma3Months", headerName: "WMA 3 meses", type: 'number',
      valueGetter(params) {
        if (!params.row.wma3Months) {
          return 0
        }
        return parseInt(params.row.wma3Months)
      },
    },
    { // wma
      field: "wma6Months", headerName: "WMA 6 meses", type: 'number',
      valueGetter(params) {
        if (!params.row.wma6Months) {
          return 0
        }
        return parseInt(params.row.wma6Months)
      },
    },
    { // wma
      field: "wma9Months", headerName: "WMA 9 meses", type: 'number',
      valueGetter(params) {
        if (!params.row.wma9Months) {
          return null
        }
        return parseInt(params.row.wma9Months)
      },
    },
    { // wma
      field: "wma12Months", headerName: "WMA 12 meses", type: 'number',
      valueGetter(params) {
        if (!params.row.wma12Months) {
          return null
        }
        return parseInt(params.row.wma12Months)
      },
    },
    {
      field: "selectedValue", headerName: "Valor Seleccionado", type: 'number',
      valueGetter(params) {
        if (!params.row.selectedValue) {
          return 0
        }
        return parseInt(params.row.selectedValue)
      },
      cellClassName: (params) => {
        return "selected"
      }
    },
    {
      field: "wrongMonths", headerName: "Meses Erroneos", type: 'number',
      valueGetter(params) {
        const selectedType = `wrongMonths${selectedSalesType.charAt(0).toUpperCase() + selectedSalesType.slice(1)}`;
        if (!params.row[selectedType]) {
          return 0
        }
        return parseInt(params.row[selectedType])
      }
    },
    { field: "monthsToBuy", headerName: "Meses a Comprar", editable: true },
    { field: "quantityToBuy", headerName: "Cantidad a Comprar", type: 'number', valueGetter(params) { return Math.round(params.row.quantityToBuy * 10) / 10 } },
    {
      field: "adjustedQuantityToBuy", headerName: "Cantidad Ajustada a Comprar", type: "number", valueGetter(params) {
        return parseInt(params.row.adjustedQuantityToBuy)
      },
      cellClassName: (params) => {
        if (params.row.adjustedQuantityToBuy / params.row.quantityToBuy > 1.3) {
          return "overLimit"
        }
        if (params.row.adjustedQuantityToBuy > 0) {
          return "toBuy"
        }
        return ""
      },
    },
    {
      field: "participationLastMonth", headerName: "% Participación último mes", type: 'number', valueGetter(params) {
        if (!params.row.participationLastMonth) {
          return 0
        }
        return Math.round(params.row.participationLastMonth * 100) / 100
      }
    },
    {
      field: "participationLast3Months", headerName: "% Participación 3 meses", type: 'number', valueGetter(params) {
        if (!params.row.participationLast3Months) {
          return 0
        }
        return Math.round(params.row.participationLast3Months * 100) / 100
      },
    },
    {
      field: "participationLast6Months", headerName: "% Participación 6 meses", type: 'number', valueGetter(params) {
        if (!params.row.participationLast6Months) {
          return 0
        }
        return Math.round(params.row.participationLast6Months * 100) / 100
      },
    },
    {
      field: "participationLast9Months", headerName: "% Participación 9 meses", type: 'number', valueGetter(params) {
        if (!params.row.participationLast9Months) {
          return 0
        }
        return Math.round(params.row.participationLast9Months * 100) / 100
      },
    },
    {
      field: "participationLast12Months", headerName: "% Participación 12 meses", type: 'number', valueGetter(params) {
        if (!params.row.participationLast12Months) {
          return 0
        }
        return Math.round(params.row.participationLast12Months * 100) / 100
      },
    },
    {
      field: "salesMonthsInWarehouse", headerName: "Meses Venta en Bodega", type: 'number',
      filterOperators: [...getGridNumericOperators(), { label: 'Es 0/0', value: 'isNaN', getApplyFilterFn: (filterItem) => (params) => isNaN(params.value) }, { label: 'Es infinito', value: 'isInfinity', getApplyFilterFn: (filterItem) => (params) => !isFinite(params.value) && !isNaN(params.value) }],
    },
  ]

  const calculateAverageSales = (row: ProductWithSalesAndInfo) => {
    let total = 0
    let count = 0
    averageMonths.forEach((m) => {
      total += row[m as "month1" | "month2" | "month3" | "month4" | "month5" | "month6" | "month7" | "month8" | "month9" | "month10" | "month11" | "month12" | "month13" | "month14" | "month15" | "month16" | "month17" | "month18"]!
      count++
    })
    let average = Math.round(total / count);
    let wrongMonths = 0;
    let color = "greenRow";
    if (topLimit !== 0 && bottomLimit !== 0) {
      const validMonths = averageMonths.filter((month) => {
        const isAboveTopLimit = row[month as "month1" | "month2" | "month3" | "month4" | "month5" | "month6" | "month7" | "month8" | "month9" | "month10" | "month11" | "month12" | "month13" | "month14" | "month15" | "month16" | "month17" | "month18"]! > average * (1 + topLimit / 100);
        const isBelowBottomLimit = row[month as "month1" | "month2" | "month3" | "month4" | "month5" | "month6" | "month7" | "month8" | "month9" | "month10" | "month11" | "month12" | "month13" | "month14" | "month15" | "month16" | "month17" | "month18"]! < average * (1 - bottomLimit / 100);

        if (isAboveTopLimit || isBelowBottomLimit) {
          wrongMonths += 1; // Contabilizar los meses malos
          return false; // Remover meses que no estén en el rango
        }

        return true; // Mantener meses válidos
      });
      // Si hay meses válidos, recalcular el promedio
      if (validMonths.length > 0) {
        const total = validMonths.reduce((acc, month) => acc + row[month as "month1" | "month2" | "month3" | "month4" | "month5" | "month6" | "month7" | "month8" | "month9" | "month10" | "month11" | "month12" | "month13" | "month14" | "month15" | "month16" | "month17" | "month18"]!, 0);
        average = Math.round(total / validMonths.length);
      }
      else {
        average = 0;
      }
      if (wrongMonths / averageMonths.length >= 2 / 3) {
        color = "redRow";
      }
      else if (wrongMonths / averageMonths.length >= 1 / 3) {
        color = "yellowRow";
      }
    }


    return { average, wrongMonths, color }
  }

  const addDataAndFilterRows = (rows: ProductWithSalesAndInfo[],
    months: number,
    salesType: string,
    includeReception: boolean,
    temporality: number,
    expectedGrowth: number,
    periodForClassification: string,
    productClassificationChecked: {
      A: boolean,
      B: boolean,
      C: boolean
    },
    newProductsChecked: boolean,
    seasonalityChecked: {
      TT: boolean,
      V: boolean,
      I: boolean
    },
    trafficLightChecked: {
      green: boolean,
      yellow: boolean,
      red: boolean
    }
  ) => {
    const filteredRows = rows.filter((r) => {
      // aqui debemos filtrar en base a los parametros
      // productos nuevos
      if ((newProductsChecked && !r.new)) {
        return false
      }
      // estacionalidad 
      if (r.seasonality === "TT" && !seasonalityChecked.TT) {
        return false
      }
      if (r.seasonality === "V" && !seasonalityChecked.V) {
        return false
      }
      if (r.seasonality === "I" && !seasonalityChecked.I) {
        return false
      }
      // clasificación de productos
      if (r[`classification${periodForClassification}` as "classificationLastMonth" | "classificationLast3Months" | "classificationLast6Months" | "classificationLast9Months" | "classificationLast12Months"] === "A" && !productClassificationChecked.A) {
        return false
      }
      if (r[`classification${periodForClassification}` as "classificationLastMonth" | "classificationLast3Months" | "classificationLast6Months" | "classificationLast9Months" | "classificationLast12Months"] === "B" && !productClassificationChecked.B) {
        return false
      }
      if (r[`classification${periodForClassification}` as "classificationLastMonth" | "classificationLast3Months" | "classificationLast6Months" | "classificationLast9Months" | "classificationLast12Months"] === "C" && !productClassificationChecked.C) {
        return false
      }
      if (!r[`classification${periodForClassification}` as "classificationLastMonth" | "classificationLast3Months" | "classificationLast6Months" | "classificationLast9Months" | "classificationLast12Months"] && !productClassificationChecked.C) {
        return false
      }
      // semaforo
      const selectedColor = `color${salesType.charAt(0).toUpperCase() + salesType.slice(1)}`;
      // en realidad es todos los colores pero para terminos de typescript lo dejo asi
      if (r[selectedColor as "colorLast3Months"] === "greenRow" && !trafficLightChecked.green) {
        return false
      }
      if (r[selectedColor as "colorLast3Months"] === "yellowRow" && !trafficLightChecked.yellow) {
        return false
      }
      if (r[selectedColor as "colorLast3Months"] === "redRow" && !trafficLightChecked.red) {
        return false
      }
      return true
    })
    const filteredRowsWithoutProductsInCart = filterRowsWithCart(filteredRows);
    let completedRows = filteredRowsWithoutProductsInCart.map((r) => {
      let selectedValue
      let wrongMonthsCustom = 0;
      let colorCustom = "greenRow";
      if (salesType === "custom") {
        selectedValue = calculateAverageSales(r).average;
        wrongMonthsCustom = calculateAverageSales(r).wrongMonths;
        colorCustom = calculateAverageSales(r).color;

      } else {
        selectedValue = r[salesType as "month18" | "last3Months" | "proyectedMonth" | "last6Months" | "last9Months" | "last12Months" | "wma3Months" | "wma6Months" | "wma9Months" | "wma12Months"]!
      }
      return {
        ...r,
        selectedValue,
        wrongMonthsCustom,
        colorCustom,
        monthsToBuy: months,
        quantityToBuy: quantityToBuy({ ...r, selectedValue, monthsToBuy: months }, temporality, expectedGrowth, includeReception),
        adjustedQuantityToBuy: getAdjustedQuantityToBuy(quantityToBuy({ ...r, selectedValue, monthsToBuy: months }, temporality, expectedGrowth, includeReception), r.packing),
        salesMonthsInWarehouse: calculateSalesMonthsInWarehouse(r, includeReception, selectedValue, expectedGrowth, temporality)
      }
    })
    if (salesType === "custom") {
      completedRows = completedRows.filter(r => {
        // semaforo con los meses seleccionados
        if (r.colorCustom === "greenRow" && !trafficLightChecked.green) {
          return false
        }
        if (r.colorCustom === "yellowRow" && !trafficLightChecked.yellow) {
          return false
        }
        if (r.colorCustom === "redRow" && !trafficLightChecked.red) {
          return false
        }
        return true
      })
    }
    if (criticalStockFilter) {
      return completedRows.filter((r) => r.salesMonthsInWarehouse <= months)
    }
    else {
      return completedRows
    }
  }


  const handleFilterModelChange = (newFilterModel: any) => {
    setFilterModel(newFilterModel);
    localStorage.setItem("filterModel", JSON.stringify(newFilterModel));
  };

  const getLocalStorageFilters = () => {
    if (!!localStorage.getItem("filterModel")) {
      return JSON.parse(localStorage.getItem("filterModel")!);
    } else {
      return null;
    }
  };

  const handleClassificationChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setProductClassificationChecked({
      ...productClassificationChecked,
      [event.target.name]: event.target.checked,
    });
  }

  const handleSeasonalityChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSeasonalityChecked({
      ...seasonalityChecked,
      [event.target.name]: event.target.checked,
    });
  }

  const handleTrafficLightChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setTrafficLightChecked({
      ...trafficLightChecked,
      [event.target.name]: event.target.checked,
    });
  }

  useEffect(() => {
    if (!openLimitModal) {
      loadCartFromLocalStorage();

      setLoading(true)
      const getLocalStorageValues = () => {
        if (!!localStorage.getItem("arturoPratValues")) {
          return JSON.parse(localStorage.getItem("arturoPratValues") as string);
        } else {
          return {
            monthsInWarehouse: 1,
            selectedSalesType: "month18",
            includeReception: false,
            temporality: 0,
            expectedGrowth: 0,
            periodForClassification: "LastMonth",
            productClassificationChecked: {
              A: true,
              B: true,
              C: true,
            },
            newProductsChecked: false,
            seasonalityChecked: {
              TT: true,
              V: true,
              I: true,
            },
            trafficLightChecked: {
              green: true,
              yellow: true,
              red: true,
            }
          };
        }
      };

      // Obtener los valores del localStorage
      const values = getLocalStorageValues();

      if (!values.setTrafficLightChecked) {
        values.setTrafficLightChecked = {
          green: true,
          yellow: true,
          red: true,
        }
      }

      setMonthsInWarehouse(values.monthsInWarehouse);
      setSelectedSalesType(values.selectedSalesType);
      setIncludeReception(values.includeReception);
      setTemporality(values.temporality);
      setExpectedGrowth(values.expectedGrowth);
      setPeriodForClassification(values.periodForClassification);
      setProductClassificationChecked(values.productClassificationChecked);
      setNewProductsChecked(values.newProductsChecked);
      setSeasonalityChecked(values.seasonalityChecked);
      setTrafficLightChecked(values.trafficLightChecked);

      const storedFilters = getLocalStorageFilters();
      if (storedFilters) {
        setFilterModel(storedFilters);
      }
      getSkusWithSales().then(
        (res) => {
          setRows(res)
          res = addDataAndFilterRows(res, values.monthsInWarehouse, values.selectedSalesType, values.includeReception, values.temporality, values.expectedGrowth, values.periodForClassification, values.productClassificationChecked, values.newProductsChecked, values.seasonalityChecked, values.trafficLightChecked)
          setFilteredRows(res)
        }
      ).finally(
        () => setLoading(false)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openLimitModal])

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setFilteredRows(addDataAndFilterRows(rows, monthsInWarehouse, selectedSalesType, includeReception, temporality, expectedGrowth, periodForClassification, productClassificationChecked, newProductsChecked, seasonalityChecked, trafficLightChecked))

    localStorage.setItem("arturoPratValues", JSON.stringify({
      monthsInWarehouse: monthsInWarehouse,
      selectedSalesType: selectedSalesType,
      includeReception: includeReception,
      temporality: temporality,
      expectedGrowth: expectedGrowth,
      periodForClassification: periodForClassification,
      productClassificationChecked: productClassificationChecked,
      newProductsChecked: newProductsChecked,
      seasonalityChecked: seasonalityChecked,
      trafficLightChecked: trafficLightChecked
    }))

  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [monthsInWarehouse, selectedSalesType, temporality, expectedGrowth, periodForClassification, productClassificationChecked, newProductsChecked, seasonalityChecked, averageMonths, includeReception, criticalStockFilter, trafficLightChecked, cart])

  useEffect(() => {
    // calculate OC amount if rows change
    let objectForAmountCalculation: ProductWithSalesAndInfo[] = []
    filteredRows.forEach((r) => {
      if (selectedRows.includes(r.sku)) {
        objectForAmountCalculation.push(r)
      }
    })
    let totalAmount = 0
    objectForAmountCalculation.forEach((r) => {
      console.log(r.sku, totalAmount)
      totalAmount += r.adjustedQuantityToBuy * r.cost
    })
    setTotalOcAmount(totalAmount)
  }, [filteredRows, selectedRows])

  return (
    <div className="p-5 h-full w-screen">
      <h2 className="mb-5">Bodega Arturo Prat</h2>
      {(selectedSalesType === "wma9Months" || selectedSalesType === "wma12Months") && (<Alert severity="info" className="mb-5">
        El WMA de 9 y 12 meses no se calcula para productos de verano o invierno
      </Alert>)}
      <div className="flex gap-2 mb-4 items-center">
        <FormControl sx={{ minWidth: 160, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <InputLabel>Seleccion Dato Venta</InputLabel>
          <Select
            value={selectedSalesType}
            label="Seleccion Dato Venta"
            onChange={(e) => setSelectedSalesType(e.target.value as "month18" | "last3Months" | "proyectedMonth" | "last6Months" | "last9Months" | "last12Months" | "wma3Months" | "wma6Months" | "wma9Months" | "wma12Months" | "custom")}
          >
            <MenuItem value="month18">Ultimo Mes</MenuItem>
            <MenuItem value="last3Months">Ultimos 3 Meses</MenuItem>
            <MenuItem value="last6Months">Ultimos 6 Meses</MenuItem>
            <MenuItem value="last9Months">Ultimos 9 Meses</MenuItem>
            <MenuItem value="last12Months">Ultimos 12 Meses</MenuItem>
            <MenuItem value="wma3Months">WMA 3 Meses</MenuItem>
            <MenuItem value="wma6Months">WMA 6 Meses</MenuItem>
            <MenuItem value="wma9Months">WMA 9 Meses</MenuItem>
            <MenuItem value="wma12Months">WMA 12 Meses</MenuItem>
            <MenuItem value="custom">Seleccionar Meses</MenuItem>
            <MenuItem value="proyectedMonth">Proyectado del Mes</MenuItem>
          </Select>
          <TextField
            label="Meses en Bodega"
            type="number"
            InputProps={{
              inputProps: {
                step: 0.01, // Set the step to allow decimal numbers
              },
            }}
            value={monthsInWarehouse}
            onChange={(e) => setMonthsInWarehouse(parseFloat(e.target.value))}
          />
        </FormControl>
        <FormControl>
          {selectedSalesType === "custom" && (
            <Button
              variant="contained"
              onClick={() => setOpenAverageModal(true)}
            >
              Seleccionar Meses
            </Button>)
          }
          <FormControlLabel control={
            <Checkbox checked={includeReception}
              onChange={(e) => setIncludeReception(e.target.checked)} />
          } label="Incluir Recepción en meses venta"
          />
          <FormControlLabel control={
            <Checkbox checked={criticalStockFilter}
              onChange={(e) => setCriticalStockFilter(e.target.checked)} />
          } label="Filtrar Stock Crítico"
          />
        </FormControl>
        <FormControl sx={{ minWidth: 160, display: 'flex', flexDirection: 'column', gap: 2 }}>

          <TextField
            label="Temporalidad (%)"
            type="number"
            value={temporality}
            onChange={(e) => setTemporality(parseInt(e.target.value))}
          />

          <TextField
            label="Crecimiento Esperado (%)"
            type="number"
            value={expectedGrowth}
            onChange={(e) => setExpectedGrowth(parseInt(e.target.value))}
          />
        </FormControl>
        <FormControl sx={{ minWidth: 150 }}>
          <FormGroup>
            <FormLabel component="legend">Clasificacion de productos</FormLabel>
            <FormControlLabel
              control={
                <Checkbox checked={productClassificationChecked.A} onChange={handleClassificationChange} name="A" />
              }
              label="Produtos A"
            />
            <FormControlLabel
              control={
                <Checkbox checked={productClassificationChecked.B} onChange={handleClassificationChange} name="B" />
              }
              label="Productos B"
            />
            <FormControlLabel
              control={
                <Checkbox checked={productClassificationChecked.C} onChange={handleClassificationChange} name="C" />
              }
              label="Productos C"
            />
          </FormGroup>
        </FormControl>
        <FormControl sx={{ minWidth: 160, display: 'flex', flexDirection: 'column', gap: 2 }}>

          <InputLabel>Periodo para clasificación</InputLabel>
          <Select
            value={periodForClassification}
            label="Periodo para clasificación"
            onChange={(e) => setPeriodForClassification(e.target.value as "LastMonth" | "Last3Months" | "Last6Months" | "Last12Months")}
          >
            <MenuItem value="LastMonth">Ultimo Mes</MenuItem>
            <MenuItem value="Last3Months">Ultimos 3 Meses</MenuItem>
            <MenuItem value="Last6Months">Ultimos 6 Meses</MenuItem>
            <MenuItem value="Last12Months">Ultimos 12 Meses</MenuItem>
          </Select>

          <FormControlLabel
            control={
              <Checkbox checked={newProductsChecked} onChange={
                (e) => setNewProductsChecked(e.target.checked)
              } />
            }
            label="Productos Nuevos"
          />
        </FormControl>
        <FormControl sx={{ minWidth: 150 }}>
          <FormGroup>
            <FormLabel component="legend">Temporada</FormLabel>
            <FormControlLabel
              control={
                <Checkbox checked={seasonalityChecked.TT} onChange={handleSeasonalityChange} name="TT" />
              }
              label="Produtos TT"
            />
            <FormControlLabel
              control={
                <Checkbox checked={seasonalityChecked.V} onChange={handleSeasonalityChange} name="V" />
              }
              label="Productos V"
            />
            <FormControlLabel
              control={
                <Checkbox checked={seasonalityChecked.I} onChange={handleSeasonalityChange} name="I" />
              }
              label="Productos I"
            />
          </FormGroup>
        </FormControl>
        <Button
          variant="contained"
          disabled={loading}
          onClick={
            () => {
              setLoading(true)
              updateStock().then((res) => {
                if (res) {
                  getSkusWithSales().then(
                    (res) => {
                      setRows(res)
                      res = addDataAndFilterRows(res, monthsInWarehouse, selectedSalesType, includeReception, temporality, expectedGrowth, periodForClassification, productClassificationChecked, newProductsChecked, seasonalityChecked, trafficLightChecked)
                      setFilteredRows(res)
                    }
                  )
                }
              }
              ).finally(
                () => setLoading(false)
              )
            }
          }>
          Actualizar Stock
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={loading}
          onClick={addToCart}
        >
          Agregar al carrito
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpenCartModal(true)
          }}
        >
          Generar Excel OC
        </Button>
        <Button variant="contained"
          color="secondary"
          onClick={
            async () => {
              let objectToExcel: ProductWithSalesAndInfo[] = []
              filteredRows.forEach((r) => {
                if (selectedRows.includes(r.sku)) {
                  objectToExcel.push(r)
                }
              })
              await createExcels.handleGenerateFullExcel(objectToExcel)
            }
          }
        >Generar Excel Completo</Button>

      </div>
      <div className="mb-5 flex">
        <div className="flex-grow">
          <Button
            variant="contained"
            onClick={
              () => setOpenLimitModal(true)
            }>
            Editar Limites
          </Button>
          <h3>Total a Agregar: {totalOcAmount.toLocaleString("es-ES")}</h3>
          <h3>Total Carrito: {cart ? cart.reduce(
            (acc, r) => acc + r.adjustedQuantityToBuy * r.cost, 0
          ).toLocaleString("es-ES") : 0}</h3>
        </div>
        <TrafficLightFilters
          trafficLightChecked={trafficLightChecked}
          handleTrafficLightChange={handleTrafficLightChange}
        />
      </div>
      <Box sx={{
        height: '85%',
        width: '100%',
        '& .selected': {
          backgroundColor: 'red',
          color: '#fff',
          fontWeight: '600',
        },
        '& .toBuy': {
          backgroundColor: 'green',
          color: '#fff',
          fontWeight: '600',
        },
        '& .overLimit': {
          backgroundColor: '#39FF14',
          color: '#fff',
          fontWeight: '600',
        }
      }}>
        <DataGridPro
          columns={columns}
          rows={filteredRows}
          getRowClassName={(params) => {
            const selectedType = `color${selectedSalesType.charAt(0).toUpperCase() + selectedSalesType.slice(1)}`;
            const color = params.row[selectedType];
            return color ? color : '';
          }}
          components={{
            Toolbar: GridToolbar,
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          loading={loading}
          getRowId={(row) => row.sku}
          pagination
          initialState={
            {
              columns: {
                columnVisibilityModel: {
                  month1: false,
                  month2: false,
                  month3: false,
                  month4: false,
                  month5: false,
                  month6: false,
                  month7: false,
                  month8: false,
                  month9: false,
                  month10: false,
                  month11: false,
                  month12: false,
                  month13: false,
                  month14: false,
                  month15: false,
                  month16: false,
                  month17: false,
                },
              },
              pinnedColumns: {
                left: ['sku', 'description', 'brand', 'provider', 'family'],
              },
            }
          }
          processRowUpdate={(newRow) => {
            let newUpdatedRow = newRow
            const updatedRows = filteredRows.map((r) => {
              if (r.sku === newRow.sku) {
                newUpdatedRow = {
                  ...r,
                  monthsToBuy: parseFloat(newRow.monthsToBuy),
                  quantityToBuy: quantityToBuy({ ...r, monthsToBuy: parseFloat(newRow.monthsToBuy) }, temporality, expectedGrowth, includeReception),
                  adjustedQuantityToBuy: getAdjustedQuantityToBuy(quantityToBuy({ ...r, monthsToBuy: parseFloat(newRow.monthsToBuy) }, temporality, expectedGrowth, includeReception), r.packing)

                }
                return newUpdatedRow
              }
              return r
            }
            )
            setFilteredRows(updatedRows)
            return Promise.resolve(newUpdatedRow)
          }}
          onProcessRowUpdateError={() => { }}
          onStateChange={(state) => {
            let selectedRowsToSet: String[] = []
            Object.keys(state.filter.visibleRowsLookup).forEach((key) => {
              if (state.filter.visibleRowsLookup[key]) {
                selectedRowsToSet.push(key)
              }
            })
            // Solo actualiza el estado si realmente cambió, para evitar problemas de re-renderizado extra
            if (JSON.stringify(selectedRowsToSet) !== JSON.stringify(selectedRows)) {
              setSelectedRows(selectedRowsToSet);
            }
          }
          }
          pageSize={500}
          filterModel={filterModel}
          onFilterModelChange={handleFilterModelChange}
        />
      </Box>
      <CustomMonthSelectModal
        open={openAverageModal}
        onClose={() => setOpenAverageModal(false)}
        averageMonths={averageMonths}
        setAverageMonths={setAverageMonths}
        setTopLimit={setTopLimit}
        setBottomLimit={setBottomLimit}
      />
      <LimitModal
        open={openLimitModal}
        onClose={() => setOpenLimitModal(false)}
      />
      <CartModal
        open={openCartModal}
        onClose={() => setOpenCartModal(false)}
        cart={cart}
        setCart={setCart}
        saveCartToLocalStorage={saveCartToLocalStorage}
      />
    </div>
  )
}

export default WarehouseView